import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Button } from 'src/components/atoms/Button';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function DrawerImage({ heading, content, contentNode, image, ctas, customData }) {
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <SectionHeader centered={customData?.flags?.centered}>
          {!!heading && <h2>{heading}</h2>}
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </SectionHeader>

        {image && (
          <FluidImg
            css={`
              margin-bottom: 20px;
            `}
            data={image}
            width={image.width}
            role="presentation"
            {...image.customData}
          />
        )}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: customData?.flags?.noCtaListPadding !== true && 80 || 0 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
